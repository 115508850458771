
import React from 'react'
import ab from '../assets/awuababy.jpg'

export default function Wink() {
	return (
		<>
		<main id='viva3dfilms'>
		<h1>AQUA.BABY</h1>
		<p>Visit <a href='https://aqua.baby/en/portfolio/underwater' target='_blank' rel='nofollow'>aqua.baby</a> for more information on ‘Nirvana Babies’. Colleague and partner in the water, Arjen Mulder, set up this successful business in Germany, Austria and now together in Spain. Check the Portfolio Page to see details and other photography we offer, including architecture, wedding, schools, etc.</p>
		</main>
		<a className='imgLink' href='https://aqua.baby/en/portfolio/underwater' target='_blank' rel='nofollow'><img src={ab} alt='wink.earth'/></a>
		</>
		)
}