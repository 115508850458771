import './App.css';

// views
import Home from './views/Home';
import Portfolio from './views/Portfolio';
import MadOffer from './views/MadOffer';
import LiquidIdentity from './views/LiquidIdentity';
import VipPhotocall from './views/VipPhotocall';
import Viva3dfilms from './views/Viva3dfilms';
import AquaBaby from './views/AquaBaby';
// components
import Footer from './components/Footer';
import Header from './components/Header';
import ScrollToTop from './helpers/ScrollToTop';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="App">
    <Router>
    <Header />
    <ScrollToTop/>
    <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/portfolio" element={<Portfolio />} />
    <Route path="/madoffer" element={<MadOffer />} />
    <Route path="/liquididentity" element={<LiquidIdentity />} />
    <Route path="/vipphotocall" element={<VipPhotocall />} />
    <Route path="/viva3dfilms" element={<Viva3dfilms />} />
    <Route path="/aquababy" element={<AquaBaby />} />
    </Routes>
    <Footer />
    <ScrollToTop/>
    </Router>

    </div>
    );
}

export default App;
