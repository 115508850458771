import React,{useState} from 'react'
import axios from "../config.js"
import Slider from 'infinite-react-carousel';
import EmailSentPopUp from '../components/EmailSentPopUp.js'

import Vip01 from '../assets/vip01.jpg'
import Vip03 from '../assets/vip01.jpg'
import Vip02 from '../assets/vip02.jpg'
import Vip04 from '../assets/vip04.jpg'
import Vip05 from '../assets/vip05.jpg'
import Vip06 from '../assets/vip06.jpg'
import Vip07 from '../assets/vip07.jpg'
import Vip08 from '../assets/vip08.jpg'
import Vip09 from '../assets/vip09.jpg'
import Vip10 from '../assets/vip10.jpg'

import Vip11 from '../assets/vip01.jpg'
import Vip12 from '../assets/vip01.jpg'
import Vip13 from '../assets/vip02.jpg'
import Vip14 from '../assets/vip04.jpg'
import Vip15 from '../assets/vip05.jpg'
import Vip16 from '../assets/vip06.jpg'
import Vip17 from '../assets/vip07.jpg'
import Vip18 from '../assets/vip08.jpg'
import Vip19 from '../assets/vip09.jpg'
import Vip20 from '../assets/vip10.jpg'

import Vip21 from '../assets/vip01.jpg'
import Vip23 from '../assets/vip01.jpg'
import Vip22 from '../assets/vip02.jpg'
import Vip24 from '../assets/vip04.jpg'
import Vip25 from '../assets/vip05.jpg'
import Vip26 from '../assets/vip06.jpg'
import Vip27 from '../assets/vip07.jpg'
import Vip28 from '../assets/vip08.jpg'
import Vip29 from '../assets/vip09.jpg'

import lucas01 from '../assets/lucas01.jpg'
import lucas03 from '../assets/lucas02.jpg'
import lucas02 from '../assets/lucas03.jpg'
import lucas04 from '../assets/lucas04.jpg'
import lucas05 from '../assets/lucas05.jpg'

import SCHMERSAL01 from '../assets/SCHMERSAL01.jpg'
import SCHMERSAL02 from '../assets/SCHMERSAL02.jpg'
import SCHMERSAL03 from '../assets/SCHMERSAL03.jpg'
import SCHMERSAL04 from '../assets/SCHMERSAL04.jpg'
import SCHMERSAL05 from '../assets/SCHMERSAL05.jpg'
import SCHMERSAL06 from '../assets/SCHMERSAL06.jpg'
import SCHMERSAL07 from '../assets/SCHMERSAL07.jpg'
import SCHMERSAL08 from '../assets/SCHMERSAL08.jpg'
import SCHMERSAL09 from '../assets/SCHMERSAL09.jpg'
import SCHMERSAL11 from '../assets/SCHMERSAL11.jpg'
import SCHMERSAL12 from '../assets/SCHMERSAL12.jpg'

export default function VipPhotocall() {
	const [renderPopUp, setRenderPopUp] = useState(false)
	const [contactForm, setContactForm] = useState({
		name: '',
		email: '',
		message: '',
		which:'contact',
		source:'egoshots.com'
	})
	
	const handleContactForm = (e) => {
		setContactForm({...contactForm, [e.target.name]: e.target.value})
	}

	const submitContactForm = async (e) => {
		e.preventDefault();
		setRenderPopUp(true)
		if(contactForm.lastname){return}else{
		try {
			const res = await axios.post('/sendEmail', {...contactForm, message:`VIP/Corporate photoshoot request: ${contactForm.message}`});
		} catch (error) {
			console.log(error);
		}
		}
		setContactForm({
		name: '',
		email: '',
		message: '',
		which:'contact',
		source:'egoshots.com'
	})
	}

	const sliderSettings =  {
		// adaptiveHeight: true,
		autoplay: true,
		centerMode: true,
		centerPadding: 100,
		arrows: false,
		arrowsBlock: false,
		duration: 100,
		shift: 10,
		autoplaySpeed: 1500,
	};

	return (
		<main id='VipPhotocall'>
		{renderPopUp && <EmailSentPopUp setRenderPopUp={setRenderPopUp}/>}
		<h1>VIP PHOTOCALL</h1>

		<p><em>VIP PHOTOCALL is a trademark by E.GO.SHOTS.</em></p>
		<p>A very successful format that allows lucky, <strong>Very Important People</strong> like you to get high quality photography while having fun.</p>
		<p>Professionally set up, lit, shot and edited portraits at <strong>incredibly low prices</strong>.</p>
		<p>For more information about this kind of photography for your family, friends and/or your <strong>special event</strong>, please fill the form below. I’ll be in touch right away.</p>
		<p>If you prefer, call me on +34 661 839 444</p>
		<Slider { ...sliderSettings } className='imageSlider topMargin3 bottomMargin3'>
		<img src={Vip01} alt="model, actor, dancer photoshoot"/>
		<img src={Vip02} alt="model, actor, dancer photoshoot"/>
		<img src={Vip03} alt="model, actor, dancer photoshoot"/>
		<img src={Vip04} alt="model, actor, dancer photoshoot"/>
		<img src={Vip05} alt="model, actor, dancer photoshoot"/>
		<img src={Vip06} alt="model, actor, dancer photoshoot"/>
		<img src={Vip07} alt="model, actor, dancer photoshoot"/>
		<img src={Vip08} alt="model, actor, dancer photoshoot"/>
		<img src={Vip09} alt="model, actor, dancer photoshoot"/>
		<img src={Vip10} alt="model, actor, dancer photoshoot"/>

		<img src={Vip11} alt="model, actor, dancer photoshoot"/>
		<img src={Vip12} alt="model, actor, dancer photoshoot"/>
		<img src={Vip13} alt="model, actor, dancer photoshoot"/>
		<img src={Vip14} alt="model, actor, dancer photoshoot"/>
		<img src={Vip15} alt="model, actor, dancer photoshoot"/>
		<img src={Vip16} alt="model, actor, dancer photoshoot"/>
		<img src={Vip17} alt="model, actor, dancer photoshoot"/>
		<img src={Vip18} alt="model, actor, dancer photoshoot"/>
		<img src={Vip19} alt="model, actor, dancer photoshoot"/>
		<img src={Vip20} alt="model, actor, dancer photoshoot"/>

		<img src={Vip21} alt="model, actor, dancer photoshoot"/>
		<img src={Vip22} alt="model, actor, dancer photoshoot"/>
		<img src={Vip23} alt="model, actor, dancer photoshoot"/>
		<img src={Vip24} alt="model, actor, dancer photoshoot"/>
		<img src={Vip25} alt="model, actor, dancer photoshoot"/>
		<img src={Vip26} alt="model, actor, dancer photoshoot"/>
		<img src={Vip27} alt="model, actor, dancer photoshoot"/>
		<img src={Vip28} alt="model, actor, dancer photoshoot"/>
		<img src={Vip29} alt="model, actor, dancer photoshoot"/>
		</Slider>




		<h2>VIP CORPORATE</h2>

		<p>Having high quality images is a must for every business today. Even businesses that don’t have a large online presence use photos to promote themselves.</p>
		<p>Take advantage of this format to get individual portraits, group photos and corporate images of your company or institution at affordable prices.</p>
		<p><strong>Lucas Fox Sitges Office</strong></p>
		<section className='fluidFlex'>
		<img src={lucas01} alt="Lucas Fox Sitges Office corporate photoshoot"/>
		<img src={lucas02} alt="Lucas Fox Sitges Office corporate photoshoot"/>
		<img src={lucas03} alt="Lucas Fox Sitges Office corporate photoshoot"/>
		<img src={lucas04} alt="Lucas Fox Sitges Office corporate photoshoot"/>
		<img src={lucas05} alt="Lucas Fox Sitges Office corporate photoshoot"/>
		</section>

<p><strong>SCHMERSAL IBERICA</strong></p>
		<section className='fluidFlex'>
		<img src={SCHMERSAL01} alt="SCHMERSAL IBERICA corporate photoshoot"/>
		<img src={SCHMERSAL02} alt="SCHMERSAL IBERICA corporate photoshoot"/>
		<img src={SCHMERSAL03} alt="SCHMERSAL IBERICA corporate photoshoot"/>
		<img src={SCHMERSAL04} alt="SCHMERSAL IBERICA corporate photoshoot"/>
		<img src={SCHMERSAL05} alt="SCHMERSAL IBERICA corporate photoshoot"/>
		<img src={SCHMERSAL06} alt="SCHMERSAL IBERICA corporate photoshoot"/>
		<img src={SCHMERSAL07} alt="SCHMERSAL IBERICA corporate photoshoot"/>
		<img src={SCHMERSAL08} alt="SCHMERSAL IBERICA corporate photoshoot"/>
		<img src={SCHMERSAL09} alt="SCHMERSAL IBERICA corporate photoshoot"/>
		<img src={SCHMERSAL11} alt="SCHMERSAL IBERICA corporate photoshoot"/>
		<img src={SCHMERSAL12} alt="SCHMERSAL IBERICA corporate photoshoot"/>
		</section>

		<form className='flexColumn topMargin3' onChange={handleContactForm} onSubmit={submitContactForm}>
		<label htmlFor="name"><strong>Name *</strong></label>
		<input value={contactForm.name} type="text" id="name" name="name" required />
		<input name='lastname' placeholder='Please enter your lastname' type='hidden' value={contactForm.lastname} />
		<label htmlFor="email"><strong>Email *</strong></label>
		<input value={contactForm.email} type="email" id="email" name="email" required />
		<label htmlFor="message"><strong>Message *</strong></label>
		<textarea value={contactForm.message} id="message" name="message" required></textarea>
		<button><strong>VIP PHOTOCALL & VIP CORPORATE </strong></button>
		</form>

		</main>

		)
}