import React from 'react'
import LiqId from '../assets/LIQUID.EYE-BLACK-color-2048x256.png'
import LiqId01 from '../assets/liqId01.jpg'
import LiqId02 from '../assets/liqId02.jpg'

import liqid101 from '../assets/liqid101.jpg'
import liqid102 from '../assets/liqid102.jpg'
import liqid103 from '../assets/liqid103.jpg'
import liqid104 from '../assets/liqid104.jpg'
import liqid105 from '../assets/liqid105.jpg'
import liqid106 from '../assets/liqid106.jpg'
import liqid107 from '../assets/liqid107.jpg'
import liqid108 from '../assets/liqid108.jpg'
import liqid109 from '../assets/liqid109.jpg'
import liqid110 from '../assets/liqid110.jpg'
import liqid111 from '../assets/liqid111.jpg'
import liqid112 from '../assets/liqid112.jpg'
import liqid113 from '../assets/liqid113.jpg'
import liqid114 from '../assets/liqid114.jpg'
import liqid115 from '../assets/liqid115.jpg'

export default function LiquidIdentity() {
	return (
		<main id='liqIdentity'>
		<img src={LiqId} alt='Liquid.Identity'/>
		<img src={LiqId01}/>
		<h1>TELLING STORIES UNDERWATER</h1>

		<p>This kind of photography is one of the most memorable and requested by everyone.</p> 
		<p>From advertising agencies to performing artists, swimmers, sportspeople, etc.</p>
		<p>As a certified SCUBA diver, safety in the water is paramount in my daily work.</p>
		<img src={LiqId02}/>
		<p>My own underwater pool/studio has a salt water system, kind on skin and eyes, ideal for this kind of photography. I also have access to many private and public swimming pools where I regularly swim and shoot in. If you prefer to do your underwater photo session in your own pool, the sea, river or lake, I will assess it with my team for your and our own safety, and for optimal visual results, as I have done on many locations around the world. </p>
		<p>From incomparable family portraits to commercial and artistic productions you are in the right place, in and around water with us.</p>
		<img className='bottomMargin2' src={liqid101} alt='liquid identity photoshoot'/>
		<img className='bottomMargin2' src={liqid102} alt='liquid identity photoshoot'/>
		<img className='bottomMargin2' src={liqid103} alt='liquid identity photoshoot'/>
		<img className='bottomMargin2' src={liqid104} alt='liquid identity photoshoot'/>
		<img className='bottomMargin2' src={liqid105} alt='liquid identity photoshoot'/>
		<img className='bottomMargin2' src={liqid106} alt='liquid identity photoshoot'/>
		<img className='bottomMargin2' src={liqid107} alt='liquid identity photoshoot'/>
		<img className='bottomMargin2' src={liqid108} alt='liquid identity photoshoot'/>
		<img className='bottomMargin2' src={liqid109} alt='liquid identity photoshoot'/>
		<img className='bottomMargin2' src={liqid110} alt='liquid identity photoshoot'/>
		<img className='bottomMargin2' src={liqid111} alt='liquid identity photoshoot'/>
		<img className='bottomMargin2' src={liqid112} alt='liquid identity photoshoot'/>
		<img className='bottomMargin2' src={liqid113} alt='liquid identity photoshoot'/>
		<img className='bottomMargin2' src={liqid114} alt='liquid identity photoshoot'/>
		<img className='bottomMargin2' src={liqid115} alt='liquid identity photoshoot'/>
		</main >
		)
}