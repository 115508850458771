import React from 'react'

import Port01 from '../assets/port01.jpg'
import Port02 from '../assets/port02.jpg'
import Port03 from '../assets/port03.jpg'
import Port04 from '../assets/port04.jpg'
import Port05 from '../assets/port05.jpg'
import Port06 from '../assets/port06.jpg'
import Port07 from '../assets/port07.jpg'
import Port08 from '../assets/port08.jpg'
import Port09 from '../assets/port09.jpg'
import Port10 from '../assets/port10.jpg'


export default function Portfolio() {
	return (
		<main>
		<section className='flexColumn portfolio'>
			<img className='topMargin2' src={Port01} alt="Woman portrait"/>
			<img className='topMargin2' src={Port02} alt="Woman portrait"/>
			<img className='topMargin2' src={Port03} alt="Woman portrait"/>
			<img className='topMargin2' src={Port04} alt="Woman portrait"/>
			<img className='topMargin2' src={Port05} alt="Woman portrait"/>
			<img className='topMargin2' src={Port06} alt="Woman portrait"/>
			<img className='topMargin2' src={Port07} alt="Woman portrait"/>
			<img className='topMargin2' src={Port08} alt="Woman portrait"/>
			<img className='topMargin2' src={Port09} alt="Woman portrait"/>
			<img className='topMargin2' src={Port10} alt="Woman portrait"/>
		</section>
		</main>

		)
}