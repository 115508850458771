import React from 'react'
import viva3dfilms from '../assets/viva3dfilms.jpg'

export default function Viva3dfilms() {
	return (
		<>
		<main id='viva3dfilms'>
		<h1>VIVA3DFILMS</h1>
		<p>Please take a look at <a href='https://viva3dfilms.com' target='_blank' rel='nofollow'>VIVA3DFILMS</a> for more information about the diversity of my work, where eVRything is possible 😉</p>
		</main>
		<a className='imgLink' href='https://viva3dfilms.com' target='_blank' rel='nofollow'><img src={viva3dfilms} alt='viva3dfilms.com'/></a>
		</>
		)
}