import React from 'react'
import { NavLink } from "react-router-dom";
import Insta from '../assets/instgram_icon.svg'

export default function Footer() {
	const styles = {
		navStyles: {
			display: 'flex',
			alignItems:'center',
			justifyContent:'space-between',
			height: 60,
			textAlign: "left",
			margin: '1em',
		},
		active: {
			margin:'.5em',
			display:'inline-block',
			textDecoration:'underline'
		},
	default: {
		textDecoration:'none',
		color: "black",
		margin:'.5em',
		display:'inline-block'
	}
}

return (
	<footer>
	<div style={styles.navStyles}>
	<nav>
	<NavLink className='btn-epic' style={({isActive}) => (isActive ? styles.active : styles.default)}
	to={"/"}>
	HOME
	</NavLink>

	<NavLink
	style={({isActive}) => (isActive ? styles.active : styles.default)}
	to={"/portfolio"}>
	PORTFOLIO
	</NavLink>

	<NavLink
	style={({isActive}) => (isActive ? styles.active : styles.default)}
	to={"/madoffer"}>
	M.A.D.&nbsp;OFFER
	</NavLink>
	</nav>
	<a className='icon' href='https://www.instagram.com/e.go.shots/' target='_blank' rel='nofollower'><img src={Insta} alt='Instagram logo'/></a>
	</div>
	<p className='bottomMargin3' style={{marginLeft:'1.5em'}}>© {(new Date()).getFullYear()} E.GO.SHOTS. All Images by Edgar González. All Rights Reserved</p>

	<p className='smallText topMargin3'><a href='https://undefeatedsoftware.dev/' target='_blank'>website by Undefeated Software</a></p>

	</footer>
	)

}
