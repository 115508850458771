import { NavLink } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import {useState} from 'react'
import Logo from '../assets/egoshots-logo-black.png'

const Header = () => {
	const [menuOpen, setMenuopen]=useState(false)
	let navigate = useNavigate();
	return (
		<div style={styles.navStyles}>

		<nav className='desktopNav'>
		<NavLink className='btn-epic' style={({isActive}) => (isActive ? styles.active : styles.default)}
		to={"/"}>
		HOME
		</NavLink>

		<NavLink
		style={({isActive}) => (isActive ? styles.active : styles.default)}
		to={"/portfolio"}>
		PORTFOLIO
		</NavLink>

		<NavLink
		style={({isActive}) => (isActive ? styles.active : styles.default)}
		to={"/madoffer"}>
		M.A.D.&nbsp;OFFER
		</NavLink>

		<NavLink
		style={({isActive}) => (isActive ? styles.active : styles.default)}
		to={"/liquididentity"}>
		LIQUID.EYEDENTITY
		</NavLink>

		<NavLink
		style={({isActive}) => (isActive ? styles.active : styles.default)}
		to={"/vipphotocall"}>
		VIP&nbsp;PHOTOCALL
		</NavLink>

		<NavLink
		style={({isActive}) => (isActive ? styles.active : styles.default)}
		to={"/viva3dfilms"}>
		VIVA3DFILMS
		</NavLink>

		<NavLink
		style={({isActive}) => (isActive ? styles.active : styles.default)}
		to={"/aquababy"}>
		AQUA.BABY
		</NavLink>
		</nav>

		<nav className='mobileNav' >
		<span onClick={()=>setMenuopen(!menuOpen)}>|||</span>
		{menuOpen&&
		<div className='mobileNavExpanded'>
		<span onClick={()=>setMenuopen(!menuOpen)}>x</span>
		<NavLink
		onClick={()=>setMenuopen(!menuOpen)} className='btn-epic' style={({isActive}) => (isActive ? styles.active : styles.default)}
		to={"/"}>
		HOME
		</NavLink>

		<NavLink
		onClick={()=>setMenuopen(!menuOpen)}
		style={({isActive}) => (isActive ? styles.active : styles.default)}
		to={"/portfolio"}>
		PORTFOLIO
		</NavLink>

		<NavLink
		onClick={()=>setMenuopen(!menuOpen)}
		style={({isActive}) => (isActive ? styles.active : styles.default)}
		to={"/madoffer"}>
		M.A.D.&nbsp;OFFER
		</NavLink>

		<NavLink
		onClick={()=>setMenuopen(!menuOpen)}
		style={({isActive}) => (isActive ? styles.active : styles.default)}
		to={"/liquididentity"}>
		LIQUID.EYEDENTITY
		</NavLink>

		<NavLink
		onClick={()=>setMenuopen(!menuOpen)}
		style={({isActive}) => (isActive ? styles.active : styles.default)}
		to={"/vipphotocall"}>
		VIP&nbsp;PHOTOCALL
		</NavLink>

		<NavLink
		onClick={()=>setMenuopen(!menuOpen)}
		style={({isActive}) => (isActive ? styles.active : styles.default)}
		to={"/viva3dfilms"}>
		VIVA3DFILMS
		</NavLink>


		<NavLink
		onClick={()=>setMenuopen(!menuOpen)}
		style={({isActive}) => (isActive ? styles.active : styles.default)}
		to={"/aquababy"}>
		AQUA.BABY
		</NavLink>
		</div>}

		</nav>

		<article className='logoGroup flexHorizontal' onClick={()=>navigate('/')}>
		<img style={styles.logoImage} src={Logo} alt='EgoShots – The Magic Language of Light'/>
		<h1>The Magic Language of Light</h1>
		</article>

		</div>
		)
}

export default Header;



const styles = {
	navStyles: {
		display: 'flex',
		alignItems:'center',
		justifyContent:'space-between',
		height: 60,
		textAlign: "left",
		margin: '1em',
	},
	logoImage: {
		maxHeight: '100px',
		width: 'auto',
	},
	active: {
		margin:'.5em',
		display:'inline-block',
		textDecoration:'underline'
	},
default: {
	textDecoration:'none',
	color: "black",
	margin:'.5em',
	display:'inline-block'
}
};