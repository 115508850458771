import React from 'react'
import EgoShots from '../assets/egoshots-logo-black-1-2048x320.png'
import Girls from '../assets/W_EGO7627-Edit-2.jpg'
import { useLocation, useNavigate } from "react-router-dom";

export default function Home() {
let navigate = useNavigate();
	return (
		<main>
		<h1>Hello, I’m Edgar González. <br/>Creative Photographer & Filmmaker.</h1>
		<img src={EgoShots}/>
		<p>High quality creative photography and filmmaking made accessible to all.</p>
		<p>What’s your visual idea? I’d love to develop it with you to make it a reality with the magic language of light as a storytelling tool.</p>
		<p>I’ve been working on film, television and photography throughout my entire career, dotted with multiple awards for many productions, mainly for BBC World and other UK channels. From documentaries to commercial productions worldwide.</p>
		<p>People have been at the centre of my work and are the essence of my inspiration. From a beauty shot in the studio to the arduos training and performance of a dance company, and everything in between, like an Olympic team underwater, you can count on me.</p>
		<img className='centeredImage' src={Girls} alt='Girls' />
		<button onClick={()=>navigate('/madoffer')} className='topMargin3 bottomMargin2'>Let’s work together</button>
		<h2>Performing Arts and Portraits Specialist.</h2>
		<h3>Take a look at some of my recent work.</h3>
		<button onClick={()=>navigate('/portfolio')} className='bottomMargin2'>Portfolio</button>
		</main>

		)
}