import React, {useState} from 'react'
import axios from "../config.js"
import EmailSentPopUp from '../components/EmailSentPopUp.js'
import Slider from 'infinite-react-carousel';

import Girl from '../assets/W_EGO7746-Edit.jpg'
import Mad3 from '../assets/mad03.jpg'
import Mad2 from '../assets/mad02.jpg'
import Mad1 from '../assets/mad01.jpg'

import MadGirl01 from '../assets/madGirl01.jpg'
import MadGirl02 from '../assets/madGirl02.jpg'
import MadGirl03 from '../assets/madGirl03.jpg'
import MadGirl04 from '../assets/madGirl04.jpg'
import MadGirl05 from '../assets/madGirl05.jpg'
import MadGirl06 from '../assets/madGirl06.jpg'
import MadGirl07 from '../assets/madGirl07.jpg'



export default function MadOffer() {

	const [renderPopUp, setRenderPopUp] = useState(false)
	const [contactForm, setContactForm] = useState({
		name: '',
		email: '',
		message: '',
		which:'contact',
		source:'egoshots.com'
	})
	
	const handleContactForm = (e) => {
		setContactForm({...contactForm, [e.target.name]: e.target.value})
	}

	const submitContactForm = async (e) => {
		e.preventDefault();
		setRenderPopUp(true)
		if(contactForm.lastname){return}else{
		try {
			const res = await axios.post('/sendEmail', {...contactForm, message:`MAD offer request: ${contactForm.message}`});
		} catch (error) {
			console.log(error);
		}
		}
		setContactForm({
		name: '',
		email: '',
		message: '',
		which:'contact',
		source:'egoshots.com'
	})
	}

	const sliderSettings =  {
		// adaptiveHeight: true,
		autoplay: true,
		centerMode: true,
		centerPadding: 50,
		arrows: false,
		arrowsBlock: false,
		duration: 100,
		shift: 10,
		autoplaySpeed: 1500,
	};

	return (
		<main >
		{renderPopUp && <EmailSentPopUp setRenderPopUp={setRenderPopUp}/>}
		<img src={Girl}/>
		<h2>M.A.D. OFFER!</h2>
		<h1>Model, Actor & Dancer</h1>
		<section className='fluidFlex'>
		<img src={Mad1} alt="model, actor, dancer photoshoot"/>
		<img src={Mad2} alt="model, actor, dancer photoshoot"/>
		<img src={Mad3} alt="model, actor, dancer photoshoot"/>
		</section>
		<h3>Professional photography and moving pictures for moving people.</h3>

		<p>This <strong>M.A.D. offer</strong> has been inspired by the very talented students at the <strong>IAB, Institute of Performing Arts Barcelona</strong>, where I’ve been shooting stills and films since they started but applies to any student of any institution.</p>
		<p>If you are bubbling with energy and ideas and need high quality, professional images to promote your looks and skills with the local and international talent agents, casting directors and directors in general, this is the offer for you.</p>
		<p>I offer custom-made photo and video packages to suit your needs regardless of how big or small they are.</p>
		<p><strong>The special student package starts at €125 for 5 professionally lit, shot and edited photos.</strong></p>
		<p>For more information on this special offer and other packages, including my underwater sessions, <strong>liquid.eyedentity</strong>, and to book your session please fill the form below</p>
		<p>or contact me on +34 661 839 444.</p>

		<form className='flexColumn topMargin3' onChange={handleContactForm} onSubmit={submitContactForm}>
		<label htmlFor="name"><strong>Name *</strong></label>
		<input  value={contactForm.name} type="text" id="name" name="name" required />
		<input name='lastname' placeholder='Please enter your lastname' type='hidden' value={contactForm.lastname} />
		<label htmlFor="email"><strong>Email *</strong></label>
		<input  value={contactForm.email} type="email" id="email" name="email" required />
		<label htmlFor="message"><strong>Message *</strong></label>
		<textarea value={contactForm.message}  id="message" name="message" required></textarea>
		<button><strong>I'm not mad to miss out</strong></button>
		</form>

		<Slider { ...sliderSettings } className='imageSlider topMargin3 bottomMargin3'>
		<img src={MadGirl01} alt="model, actor, dancer photoshoot"/>
		<img src={MadGirl02} alt="model, actor, dancer photoshoot"/>
		<img src={MadGirl03} alt="model, actor, dancer photoshoot"/>
		<img src={MadGirl04} alt="model, actor, dancer photoshoot"/>
		<img src={MadGirl05} alt="model, actor, dancer photoshoot"/>
		<img src={MadGirl06} alt="model, actor, dancer photoshoot"/>
		<img src={MadGirl07} alt="model, actor, dancer photoshoot"/>
		</Slider>


		</main>

		)
}